import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

export interface APIBadgesLinkResponse {
  appStoreLink: string;
  googlePlayLink: string;
}
export const getBadgesLinks = async (
  flowApi: PlatformControllerFlowAPI,
  metaSiteId: string,
) => {
  try {
    const { data } = await flowApi.httpClient.get<APIBadgesLinkResponse>(
      `/_api/branded-apps/v1/app/public-config`, {
        headers: {
          Authorization: flowApi.controllerConfig.appParams.instance,
        },
        // params: {
        //   ids: metaSiteId,
        // },
      },
    );

    return data;
  } catch (err) {
    return { appStoreLink: '', googlePlayLink: '' };
  }
};
