import model from './model';

export default model.createController(({ $w, $widget, flowAPI }) => {
  const { t } = flowAPI.translations;

  $widget.onPropsChanged((oldProps, newProps) => {
    if (newProps.qrCode) {
      $w('#imageX1').src = newProps.qrCode;
    }
  });

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      $w('#imageX1').alt = t('download_my_app.widget.qr.alt"');

      // const { qrCode } = $widget.props;
      // if (qrCode) {
      //   $w('#imageX1').src = qrCode;
      // }
    },
    exports: {},
  };
});
